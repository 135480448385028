<template>
  <div>
    <b-row class="z-service-payment-detail-header">
      <b-col cols="4" md="4" class="d-flex justify-content-between">
        <span class="h6 font-weight-bold">
          Desglose de tarifa
        </span>
      </b-col>
      <b-col cols="8" md="8" class="text-right mb-3">
        <div>
          <status-payment :status="service.paymentStatus" />
          <div class="mt-2">
            <div class="z-section">
              {{ paymentStatusMessage }}
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import StatusPayment from "@/app/components/StatusPayment";
import ServicePaymentStatus from "@/constants/services/payment-status";
import ServiceStatus from "@/constants/services/status";
import ServiceBillingStatus from "@/constants/services/billing-status";
import { getPaymentStatusColorVariant } from "@/utils/service-status";

export default {
  name: "ServicePaymentDetailHeader",

  components: {
    StatusPayment
  },

  props: {
    service: {
      type: Object,
      required: true
    }
  },

  computed: {
    colorVariant() {
      return getPaymentStatusColorVariant(this.service.paymentStatus);
    },

    isPayedWithWallet() {
      return this.service.walletId != null;
    },

    paymentStatusMessage() {
      if (
        this.service.status === ServiceStatus.NUM_FINISHED &&
        this.service.paymentStatus === ServicePaymentStatus.NUM_PAID &&
        !this.isPayedWithCreditCard &&
        !this.isPayedWithWallet
      ) {
        return "Cargo añadido a tu cuenta";
      } else if (
        this.service.status === ServiceStatus.NUM_FINISHED &&
        this.service.paymentStatus === ServicePaymentStatus.NUM_NOT_PAID
      ) {
        return "El cargo fue rechazado";
      } else if (
        this.service.billingStatus === ServiceBillingStatus.NUM_INVOICED
      ) {
        return "Cargo y factura generados con éxito";
      } else if (
        this.service.paymentStatus === ServicePaymentStatus.NUM_PAID ||
        this.chargeForCanceledService
      ) {
        return "Se realizó correctamente el cargo";
      } else if (this.noChargeForCanceledService) {
        return "No se realizó ningún cargo a tu tarjeta";
      }
      return "";
    },

    isPayedWithCreditCard() {
      return this.service.cardInfo != null;
    },

    showPaymentMethod() {
      return this.isPayedWithWallet || this.isPayedWithCreditCard;
    },

    cardInfo() {
      const last4 = this.service?.cardInfo?.last4;
      if (last4) {
        return `${last4}`;
      }
      return "";
    },

    creditCardBrand() {
      const brand = this.service?.cardInfo?.brand ?? "";
      return brand.toUpperCase();
    }
  }
};
</script>

<style lang="scss" scoped>
.z-service-payment-detail-header {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  .z-section {
    font-size: 12px;

    &.title {
      margin: 0 0 10px;
      color: rgba($black, 0.4);
    }
  }

  a {
    color: var(--primary) !important;
  }
}
</style>
