const CREDIT = "Crédito";
const NOT_PAID = "Por pagar";
const PAID = "Pagado";
const REIMBURSED = "Reembolsado";

const NUM_CREDIT = 0;
const NUM_NOT_PAID = 1;
const NUM_PAID = 2;
const NUM_REIMBURSED = 3;

const get = {};
get[NUM_CREDIT] = CREDIT;
get[NUM_NOT_PAID] = NOT_PAID;
get[NUM_PAID] = PAID;
get[NUM_REIMBURSED] = REIMBURSED;

const options = [
  { value: NUM_CREDIT, text: CREDIT },
  { value: NUM_NOT_PAID, text: NOT_PAID },
  { value: NUM_PAID, text: PAID },
  { value: NUM_REIMBURSED, text: REIMBURSED }
];

export default {
  get,
  options,
  NUM_CREDIT,
  NUM_NOT_PAID,
  NUM_PAID,
  NUM_REIMBURSED,
  CREDIT,
  NOT_PAID,
  PAID,
  REIMBURSED
};
