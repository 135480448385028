<template>
  <div>
    <service-payment-detail-header :service="service" />
    <package-service-payment-detail
      v-if="service.type === serviceType.NUM_PACKAGE_DELIVERY"
      :service="service"
    >
      <template #custom-items>
        <payment-method-item :service="service" />
      </template>
    </package-service-payment-detail>
    <multipoint-service-payment-detail
      v-else
      :is-account-user="isAccountUser"
      :is-consolidated-user="isConsolidatedUser"
      :service="service"
      :service-brand="service.brand"
      :service-card="service.card"
      :service-wallet-id="service.walletId"
      :service-time="serviceTime"
      :currency="serviceCity.currency"
    >
      <template #custom-items>
        <payment-method-item :service="service" />
      </template>
    </multipoint-service-payment-detail>
    <hr class="m-0 mb-3" />
    <service-payment-detail-invoice
      :service="service"
      @invoice-created="$emit('reload')"
      @invoice-error="$emit('reload')"
    />
  </div>
</template>

<script>
import MultipointServicePaymentDetail from "./MultipointServicePaymentDetail";
import PackageServicePaymentDetail from "./PackageServicePaymentDetail";
import PaymentMethodItem from "./PaymentMethodItem";
import ServicePaymentDetailHeader from "./ServicePaymentDetailHeader";
import ServiceBillingStatus from "@/constants/services/billing-status";
import ServicePaymentStatus from "@/constants/services/payment-status";
import ServiceStatus from "@/constants/services/status";
import ServiceType from "@zubut/common/src/constants/services/type";
import ServicePaymentDetailInvoice from "./ServicePaymentDetailInvoice";

export default {
  name: "ServicePaymentDetail",

  components: {
    MultipointServicePaymentDetail,
    PackageServicePaymentDetail,
    PaymentMethodItem,
    ServicePaymentDetailHeader,
    ServicePaymentDetailInvoice
  },

  props: {
    isAccountUser: {
      type: Boolean,
      default: false
    },
    isConsolidatedUser: {
      type: Boolean,
      default: false
    },
    generatingInvoice: {
      type: Boolean,
      default: false
    },
    service: {
      type: Object,
      required: true
    },
    serviceTime: {
      type: Number,
      default: 0
    },
    serviceCity: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      serviceType: ServiceType
    };
  },

  computed: {
    isPayedService() {
      return this.service.paymentStatus === ServicePaymentStatus.NUM_PAID;
    },

    isPayedWithWallet() {
      return this.service.walletId != null;
    },

    isBilledService() {
      return this.service.billingStatus === ServiceBillingStatus.NUM_INVOICED;
    },

    isFinishedService() {
      return this.service.status === ServiceStatus.NUM_FINISHED;
    },

    hasInvoiceError() {
      return (
        this.service.billingStatus === ServiceBillingStatus.NUM_INVOICE_ERROR
      );
    }
  }
};
</script>
