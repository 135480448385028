import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("Invoices");

const Invoices = Object.assign(
  {},
  {
    relation: "",

    downloadInvoice(params) {
      return loopback.get(`/Invoices/downloadInvoice`, {
        params,
        responseType: "blob"
      });
    }
  },
  modelObject
);

export default Invoices;
