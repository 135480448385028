export default function(globalGroup) {
  return {
    methods: {
      notify({
        group = globalGroup,
        title,
        text,
        type,
        duration = 5000,
        data = {}
      }) {
        this.$notify({
          group,
          title,
          text,
          type,
          duration,
          data
        });
      }
    }
  };
}
