const NOT_INVOICED = "Sin facturar";
const INVOICED = "Facturado";
const INVOICE_ERROR = "Error al facturar";

const NUM_NOT_INVOICED = 0;
const NUM_INVOICED = 1;
const NUM_INVOICE_ERROR = 2;

const get = {};
get[NUM_NOT_INVOICED] = NOT_INVOICED;
get[NUM_INVOICED] = INVOICED;
get[NUM_INVOICE_ERROR] = INVOICE_ERROR;

export default {
  get,
  NUM_NOT_INVOICED,
  NUM_INVOICED,
  NUM_INVOICE_ERROR,
  NOT_INVOICED,
  INVOICED,
  INVOICE_ERROR
};
