import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("Transactions");

const Transactions = Object.assign(
  {},
  {
    relation: "",

    createInvoice(id) {
      return loopback.post(`/Transactions/${id}/createInvoice`);
    }
  },
  modelObject
);

export default Transactions;
