const CREATED = "Creado";
const REQUESTING = "Solicitando";
const ACTIVE = "Activo";
const FINISHED = "Finalizado";
const CLIENT_CANCELLED = "Cancelado por cliente";
const ZUBUT_CANCELLED = "Cancelado por Zubut";
const DRIVER_CANCELLED = "Cancelado por mensajero";
const SCHEDULED = "Agendado";
const DRIVER_NOT_AVAILABLE = "Mensajeros no disponibles";

// This status is not shown to the user.
const IN_REVISION = "Por revisar";
const NUM_IN_REVISION = 7;

const NUM_CREATED = 0;
const NUM_REQUESTING = 1;
const NUM_ACTIVE = 2;
const NUM_FINISHED = 3;
const NUM_CLIENT_CANCELLED = 4;
const NUM_DRIVER_CANCELLED = 5;
const NUM_SCHEDULED = 6;
const NUM_DRIVER_NOT_AVAILABLE = 8;
const NUM_ZUBUT_CANCELLED = 9;

const get = {};
get[NUM_CREATED] = CREATED;
get[NUM_REQUESTING] = REQUESTING;
get[NUM_ACTIVE] = ACTIVE;
get[NUM_FINISHED] = FINISHED;
get[NUM_CLIENT_CANCELLED] = CLIENT_CANCELLED;
get[NUM_ZUBUT_CANCELLED] = ZUBUT_CANCELLED;
get[NUM_DRIVER_CANCELLED] = DRIVER_CANCELLED;
get[NUM_SCHEDULED] = SCHEDULED;
get[NUM_DRIVER_NOT_AVAILABLE] = DRIVER_NOT_AVAILABLE;
get[NUM_IN_REVISION] = IN_REVISION;

const CANCELLED_STATUS = [
  NUM_CLIENT_CANCELLED,
  NUM_ZUBUT_CANCELLED,
  NUM_DRIVER_CANCELLED
];

const options = Object.keys(get).map(key => ({
  text: get[key],
  value: parseInt(key)
}));

export default {
  get,
  options,
  CREATED,
  REQUESTING,
  ACTIVE,
  FINISHED,
  CLIENT_CANCELLED,
  ZUBUT_CANCELLED,
  DRIVER_CANCELLED,
  SCHEDULED,
  DRIVER_NOT_AVAILABLE,
  NUM_CREATED,
  NUM_REQUESTING,
  NUM_ACTIVE,
  NUM_FINISHED,
  NUM_CLIENT_CANCELLED,
  NUM_ZUBUT_CANCELLED,
  NUM_DRIVER_CANCELLED,
  NUM_SCHEDULED,
  NUM_DRIVER_NOT_AVAILABLE,
  NUM_IN_REVISION,
  CANCELLED_STATUS
};
