<template>
  <div class="border rounded p-4">
    <div class="title font-weight-bold h6 mb-3">
      Opciones adicionales
    </div>
    <b-list-group flush class="z-service-additional-detail">
      <b-list-group-item>
        <div>Firma de recibido</div>
        <div :class="{ disabled: !additionalOptions.signature }">
          {{ additionalOptions.signature ? "Si" : "No" }}
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div>Solicitó que el mensajero regresara al origen</div>
        <div :class="{ disabled: !additionalOptions.driverReturn }">
          {{ additionalOptions.driverReturn ? "Si" : "No" }}
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { formatMoney } from "@/utils/money";

export default {
  name: "ServiceDetailTabsAdditionalOptions",

  props: {
    additionalOptions: {
      type: Object,
      default: () => ({
        driverReturn: false,
        signature: false
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.z-service-additional-detail {
  font-size: 12px;

  .list-group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 0rem;
  }

  .disabled {
    color: $raven;
  }
}
</style>
