<template>
  <div>
    <b-card class="service-detail">
      <loading-spinner v-if="loading"></loading-spinner>
      <div v-else>
        <b-row>
          <b-col cols="12" sm="6">
            <go-back-title
              :title="title"
              with-action
              @back-action="$router.go(-1)"
            />
          </b-col>
          <b-col sm="6" class="mt-3 mt-sm-0 d-flex justify-content-end">
            <div>
              <z-button size="md" variant="secondary" fit="auto" @click="print">
                <font-awesome-icon icon="print" size="lg" />
              </z-button>
              <z-button
                size="md"
                variant="primary"
                class="ml-3"
                @click="toggleShareServiceModal(true)"
              >
                Compartir viaje
              </z-button>
              <z-button
                v-if="isPayable"
                size="md"
                variant="primary"
                class="ml-3"
                @click="showChargeModal = true"
              >
                Pagar
              </z-button>
            </div>
          </b-col>
        </b-row>
        <hr class="mb-4" />
        <b-row class="pb-4">
          <b-col md="6" class="px-3">
            <div class="border rounded p-4">
              <b-col class="px-0 mb-3">
                <div
                  class="d-flex justify-content-between align-items-start mb-4"
                >
                  <div>
                    <div class="h6 font-weight-bold">
                      Detalle del viaje
                    </div>
                    <div class="created-date">
                      Creado el {{ createdAtDate }}
                    </div>
                    <div class="created-date">Ciudad {{ cityName }}</div>
                  </div>
                  <status-service
                    v-if="service"
                    :status="service.status"
                    :cancel-reason="service.cancelReason"
                  />
                </div>
                <div class="d-flex">
                  <div v-if="service && service.courier" class="flex-grow-1">
                    <z-user-info
                      type="driver"
                      :image="service.courier.image"
                      :name="service.courier.name"
                      :phone="service.courier.phone"
                    />
                  </div>
                </div>
              </b-col>
              <div v-if="!isPackageService" class="mb-3">
                <service-detail-destinations
                  :destinations="destinations"
                  :service-type="service.type"
                />
              </div>
            </div>
          </b-col>
          <b-col md="6" class="px-3 mt-4 mt-md-0">
            <div>
              <service-detail-tabs
                v-model="tabIndex"
                :service="service"
                @show-service-modal="showCancelServiceModal"
                @reload="getService"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <confirmation-dialog
      :show="showCancelModal"
      @accept="cancelService"
      @cancel="hideCancelServiceModal"
    >
      ¿Seguro que deseas cancelar la entrega?
    </confirmation-dialog>
    <share-service-modal
      :show.sync="showShareModal"
      :service-id="$route.params.id"
    />
    <charge-transaction-modal
      :show.sync="showChargeModal"
      :service="service || undefined"
      :client="client"
      :amount="total"
      :subtotal="subtotal"
      :tax="tax"
      @update-listing="getService"
    />
    <notifications group="notification-service-detail" />
  </div>
</template>

<script>
import { parseISO } from "date-fns";
import { format } from "@zubut/common/src/utils/time";
import ConfirmationDialog from "@/app/components/ConfirmationDialog";
import GoBackTitle from "@zubut/common/src/components/GoBackTitle";
import { makeShortUUID } from "@/utils/strings";
import serviceRate from "@/mixins/serviceRate";
import lazyLoad from "@/app/components/lazyLoad";
import notifyMixin from "@/mixins/notify";
import StatusService from "@zubut/common/src/components/StatusService";
import ServiceDetailTabs from "@/app/components/ServiceDetailTabs/ServiceDetailTabs";
import ServiceStatus from "@/constants/services/status";
import ServicePaymentStatus from "@/constants/services/payment-status";
import ServiceType from "@zubut/common/src/constants/services/type";
import IncidencesConstants from "@/constants/services/incidences";
import ZUserInfo from "@/app/components/ZUserInfo";
import ServiceDetailDestinations from "./ServiceDetailDestinations";
import Http from "@zubut/common/src/constants/http";

/* Lazy loaded components */
const ShareServiceModal = () => lazyLoad(import("./ShareServiceModal"));
const ChargeTransactionModal = () =>
  lazyLoad(import("@/app/components/ChargeTransactionModal"));

export default {
  name: "ServiceDetail",

  components: {
    ChargeTransactionModal,
    ConfirmationDialog,
    GoBackTitle,
    ShareServiceModal,
    ServiceDetailDestinations,
    ServiceDetailTabs,
    StatusService,
    ZUserInfo
  },

  mixins: [notifyMixin("notifications"), serviceRate],

  data() {
    return {
      service: null,
      tabIndex: 0,
      generatingInvoice: false,
      showCancelModal: false,
      showShareModal: false,
      showChargeModal: false,
      loading: true
    };
  },

  computed: {
    createdAtDate() {
      if (this.service?.createdAt) {
        return format(
          parseISO(this.service.createdAt),
          "d 'de' MMM. 'de' yyyy h:mm a"
        );
      }
      return "";
    },

    destinations() {
      return this.service?.destinations || [];
    },

    isPayable() {
      return (
        (this.service?.status === ServiceStatus.NUM_FINISHED ||
          (ServiceStatus.CANCELLED_STATUS.includes(this.service?.status) &&
            this.service?.paymentDetails?.subtotal > 0)) &&
        this.service?.paymentStatus === ServicePaymentStatus.NUM_NOT_PAID
      );
    },

    total() {
      return this.service?.paymentDetails?.total ?? 0;
    },

    subtotal() {
      return this.service?.paymentDetails?.subtotal ?? 0;
    },

    tax() {
      return this.service?.paymentDetails?.tax ?? 0;
    },

    client() {
      return this.service?.client;
    },

    serviceId() {
      return this.service?.id;
    },

    shortServiceId() {
      return makeShortUUID(this.$route.params.id);
    },

    title() {
      return `Detalle de entrega ${this.shortServiceId}`;
    },

    isPackageService() {
      return this.service?.type === ServiceType.NUM_PACKAGE_DELIVERY;
    },

    cityName() {
      return this.service?.city?.name ? `${this.service?.city?.name}` : "";
    }
  },

  beforeMount() {
    this.getService();
  },

  methods: {
    print() {
      this.changeToTabOne();
      setTimeout(() => {
        window.print();
      }, 100);
    },

    changeToTabOne() {
      this.tabIndex = 0;
    },

    showCancelServiceModal() {
      this.showCancelModal = true;
    },

    hideCancelServiceModal() {
      this.showCancelModal = false;
    },

    toggleShareServiceModal(show) {
      this.showShareModal = show;
    },

    cancelService() {
      this.hideCancelServiceModal();
      this.$store
        .dispatch("services/cancel", { id: this.service.id })
        .then(() => {
          this.getService();
        })
        .catch(err => {
          this.$captureError(err);
          console.log(err);
          this.notify({
            type: "error",
            text: "Error al cancelar el entrega"
          });
        });
    },

    createInvoice() {
      this.generatingInvoice = true;
      this.$store
        .dispatch("services/createInvoice", this.service.id)
        .then(() => {
          this.notify({
            type: "success",
            text: "Entrega facturado exitosamente"
          });
        })
        .catch(err => {
          this.$captureError(err);
          console.error(err);
          this.notify({
            type: "error",
            text: "Error al facturar el entrega"
          });
        })
        .finally(() => {
          this.getService();
          this.generatingInvoice = false;
        });
    },

    parseDate(date, formatStr) {
      return format(parseISO(date), formatStr);
    },

    getService() {
      this.loading = true;
      this.$store
        .dispatch("services/getDetail", this.$route.params.id)
        .then(service => {
          // Show only client and package return incidents
          const validIncidences = [
            IncidencesConstants.NUM_INCIDENCE_CLIENT,
            IncidencesConstants.NUM_INCIDENCE_RETURN
          ];
          if (service.incidences) {
            service.incidences.forEach(incidence => {
              incidence.time = this.parseDate(incidence.createdAt, "h:mm a");
              const order = incidence.order;
              if (
                order != null &&
                validIncidences.includes(incidence.incidenceId)
              ) {
                (service.destinations[order].incidences =
                  service.destinations[order].incidences || []).push(incidence);
              }
            });
          }
          this.service = service;
          this.loading = false;
        })
        .catch(err => {
          if (err.statusCode === Http.NOT_FOUND) {
            this.$store.commit("setDisplayNotFound", true);
          } else {
            this.$captureError(err);
            this.loading = false;
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.service-detail {
  background-color: $white;
  border-radius: 1em;
  border: 1px solid $solitude;

  ::v-deep #service-detail-map {
    height: 250px;
    width: 100%;
    margin-bottom: 30px;
    border-radius: 10px;
  }

  .created-date {
    font-size: 12px;
  }
}

@media print {
  .service-detail {
    ul :nth-child(2),
    ul :nth-child(3),
    ul :nth-child(4),
    ul :nth-child(5) {
      display: none !important;
    }
    .signature {
      margin-top: 150%;
      display: block;
    }
  }
}
</style>
