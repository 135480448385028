<template>
  <b-tabs
    :value="tabIndex"
    content-class="pt-4 h-100 pb-5"
    @input="$emit('input', $event)"
  >
    <b-tab active title="Resumen">
      <resume-tab :service="service" @reload="$emit('reload')" />
    </b-tab>
    <b-tab title="Registros">
      <logs-tab
        :service="service"
        @cancel-schedule-service="$emit('show-service-modal')"
      />
    </b-tab>
    <b-tab title="Adicionales">
      <additional-options-tab :additional-options="additionalOptions" />
    </b-tab>
    <b-tab v-if="showMap && service != null" lazy title="Mapa">
      <map-tab :service="service" />
    </b-tab>
    <b-tab v-if="incidences.length > 0" title="Incidencias">
      <incidences-tab :incidences="incidences" />
    </b-tab>
    <b-tab v-if="hasSignature" title="Firmas">
      <signatures-tab :signatures="signatures" />
    </b-tab>
    <b-tab v-if="deliveryProofList.length > 0" title="Evidencia de entrega">
      <delivery-proof-tab :delivery-proof="deliveryProofList" />
    </b-tab>
  </b-tabs>
</template>

<script>
import { parseISO } from "date-fns";
import { format } from "@zubut/common/src/utils/time";
import AdditionalOptionsTab from "./ServiceDetailTabsAdditionalOptions";
import IncidencesTab from "./ServiceDetailTabsIncidences";
import LogsTab from "@/app/components/ServiceLogs/ServiceLogs";
import ResumeTab from "./ServiceDetailTabsResume/ResumeTab";
import SignaturesTab from "./ServiceDetailTabsSignatures";
import DeliveryProofTab from "./ServiceDetailTabsDeliveryProof";
import ServiceStatusConstants from "@/constants/services/status";
import IncidencesConstants from "@/constants/services/incidences";
import ServiceType from "@zubut/common/src/constants/services/type";
import DestinationReceiver from "@/constants/services/destination-receiver";

const MapTab = () => import("./ServiceDetailTabsMap");

export default {
  name: "ServiceDetailTabs",

  components: {
    AdditionalOptionsTab,
    IncidencesTab,
    LogsTab,
    SignaturesTab,
    DeliveryProofTab,
    ResumeTab,
    MapTab
  },

  props: {
    tabIndex: {
      type: Number,
      default: 0
    },
    service: {
      type: Object,
      required: true
    }
  },

  computed: {
    additionalOptions() {
      if (this.service && this.service.additional) {
        return this.service.additional;
      }
      return {};
    },

    checkPoints() {
      return this.service.resume.checkPoints;
    },

    destinations() {
      if (
        !ServiceStatusConstants.CANCELLED_STATUS.includes(
          this.service.status
        ) &&
        this.service.resume &&
        this.service.resume.signatures
      ) {
        const destinations = [...this.service.destinations];
        this.service.resume.signatures.forEach((signature, index) => {
          destinations[index].signed = format(
            parseISO(signature.time),
            "h:mm a"
          );
        });
        return destinations;
      }
      return this.service.destinations;
    },

    hasSignature() {
      return (
        this.service.resume &&
        this.service.resume.checkPoints &&
        this.service.resume.checkPoints.some(ckp => ckp.signature)
      );
    },

    incidences() {
      const validIncidences = [
        IncidencesConstants.NUM_INCIDENCE_RETURN_BY_DRIVER
      ];
      if (this.service.incidences && this.service.incidences.length > 0) {
        return this.service.incidences.filter(incidence =>
          validIncidences.includes(incidence.incidenceId)
        );
      }
      return [];
    },

    signatures() {
      const signatures = [];
      this.destinations.forEach((dest, index) => {
        if (this.checkPoints[index] && this.checkPoints[index].signature) {
          const signature = { name: dest.name };
          signature.time = format(
            parseISO(this.checkPoints[index].delivered),
            "h:mm a"
          );
          signature.image = this.checkPoints[index].signature;
          signatures.push(signature);
        }
      });
      return signatures;
    },

    deliveryProofList() {
      const proofList = [];

      this.destinations.forEach((destination, index) => {
        if (destination.deliveryProof) {
          proofList.push({
            receiverType:
              destination.deliveryProof?.receiverType ??
              DestinationReceiver.OWNER,
            receiverName: destination.deliveryProof?.receiverName ?? "Unknown",
            destinationName: destination.name ?? `Destino ${index++}.`
          });
        }
      });

      return proofList;
    },

    isPackageService() {
      return this.service.type === ServiceType.NUM_PACKAGE_DELIVERY;
    },

    showMap() {
      return !this.isPackageService;
    }
  }
};
</script>
