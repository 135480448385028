const CLIENT_IS_NOT_IN_ADDRESS = "El cliente no se encuentra en el domicilio.";
const ADRESS_DOES_NOT_EXIST = "Domicilio no existe.";
const CLIENT_CANCELLED_SERVICE_BEFORE_DELIVERY =
  "Cliente cancelo el pedido antes de la entrega.";
const PRODUCTS_OR_QUANTITY_DO_NOT_MATCH_SERVICE =
  "Los productos o la cantidad no coinciden con el pedido.";
const PRODUCTS_DAMAGED_OR_ARE_LOW_QUALITY =
  "Los productos están dañados o no cumplen con la calidad sugerida.";
const THERE_WAS_AN_ERROR_TRYING_TO_REQUEST_SERVICE =
  "Hubo un error al pedir el servicio.";
const PROBLEM_TRYING_TO_LOCATE_DRIVER =
  "Tengo un problema al tratar de localizar al mensajero.";
const SERVICE_NO_LONGER_REQUIRED = "El servicio ya no es requerido.";
const DRIVERS_NOT_AVAILABLE = "Mensajeros no disponibles";

const NUM_CLIENT_IS_NOT_IN_ADDRESS = 1;
const NUM_ADRESS_DOES_NOT_EXIST = 2;
const NUM_CLIENT_CANCELLED_SERVICE_BEFORE_DELIVERY = 3;
const NUM_PRODUCTS_OR_QUANTITY_DO_NOT_MATCH_SERVICE = 4;
const NUM_PRODUCTS_DAMAGED_OR_ARE_LOW_QUALITY = 5;
const NUM_THERE_WAS_AN_ERROR_TRYING_TO_REQUEST_SERVICE = 6;
const NUM_PROBLEM_TRYING_TO_LOCATE_DRIVER = 7;
const NUM_SERVICE_NO_LONGER_REQUIRED = 8;
const NUM_DRIVERS_NOT_AVAILABLE = 9;

export default {
  CLIENT_IS_NOT_IN_ADDRESS,
  ADRESS_DOES_NOT_EXIST,
  CLIENT_CANCELLED_SERVICE_BEFORE_DELIVERY,
  PRODUCTS_OR_QUANTITY_DO_NOT_MATCH_SERVICE,
  PRODUCTS_DAMAGED_OR_ARE_LOW_QUALITY,
  THERE_WAS_AN_ERROR_TRYING_TO_REQUEST_SERVICE,
  PROBLEM_TRYING_TO_LOCATE_DRIVER,
  SERVICE_NO_LONGER_REQUIRED,
  DRIVERS_NOT_AVAILABLE,
  NUM_CLIENT_IS_NOT_IN_ADDRESS,
  NUM_ADRESS_DOES_NOT_EXIST,
  NUM_CLIENT_CANCELLED_SERVICE_BEFORE_DELIVERY,
  NUM_PRODUCTS_OR_QUANTITY_DO_NOT_MATCH_SERVICE,
  NUM_PRODUCTS_DAMAGED_OR_ARE_LOW_QUALITY,
  NUM_THERE_WAS_AN_ERROR_TRYING_TO_REQUEST_SERVICE,
  NUM_PROBLEM_TRYING_TO_LOCATE_DRIVER,
  NUM_SERVICE_NO_LONGER_REQUIRED,
  NUM_DRIVERS_NOT_AVAILABLE
};
