const NUM_RETURN_NO_TIME = 101;
const NUM_RETURN_DANGER_ZONE = 102;

const RETURN_NO_TIME = "No tengo tiempo para completar las entregas";
const RETURN_DANGER_ZONE = "Zona de riesgo/difícil acceso";

const get = {};
get[NUM_RETURN_NO_TIME] = RETURN_NO_TIME;
get[NUM_RETURN_DANGER_ZONE] = RETURN_DANGER_ZONE;

export default {
  get
};
