<template>
  <div class="resume-stats border rounded p-4">
    <b-row>
      <b-col>
        <div class="h6 font-weight-bold">Kilómetros recorridos</div>
        <div>{{ kms }} kms</div>
      </b-col>
      <b-col>
        <div class="h6 font-weight-bold">Tiempo servicio</div>
        <div>{{ timeFormatted }}</div>
      </b-col>
      <b-col>
        <div class="h6 font-weight-bold">Tiempo estimado</div>
        <div>{{ estimatedTimeFormatted }}</div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { minutesToHours } from "@zubut/common/src/utils/time";

export default {
  name: "ResumeStats",

  props: {
    kms: {
      type: Number,
      default: 0
    },
    estimatedTime: {
      type: Number,
      default: 0
    },
    time: {
      type: Number,
      default: 0
    }
  },

  computed: {
    estimatedTimeFormatted() {
      const time = Math.round(this.estimatedTime);
      if (time > 60) {
        return `${minutesToHours(time)} hrs`;
      }
      return `${time} mins`;
    },

    timeFormatted() {
      const time = Math.round(this.time ?? 0);
      if (time > 60) {
        return `${minutesToHours(time)} hrs`;
      }
      return `${time} mins`;
    }
  }
};
</script>

<style lang="scss" scoped>
.resume-stats {
  font-size: 12px;
}
</style>
