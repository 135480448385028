<template>
  <div class="service-detail-tabs-signatures border rounded p-4">
    <div class="font-weight-bold h6 mb-3">
      Firmas
    </div>
    <b-list-group flush>
      <b-list-group-item
        v-for="(signature, index) in signatures"
        :key="`${index}-${signature.id}`"
      >
        <div>Firma en {{ signature.name }}, a las {{ signature.time }}</div>
        <div class="text-right">
          <img :src="'data:image/jpeg;base64,' + signature.image" />
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
export default {
  name: "ServiceDetailTabsSignatures",

  props: {
    signatures: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
.service-detail-tabs-signatures {
  font-size: 12px;

  .list-group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 0rem;
  }
}
</style>
