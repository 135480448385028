const OWNER = "El titular";
const SECURITY_GUARD = "Guardia de seguridad";
const OWNER_RELATIVE = "Pariente del titular";
const OWNER_NEIGHBOUR = "Vecino del titular";

const NUM_OWNER = 0;
const NUM_SECURITY_GUARD = 1;
const NUM_OWNER_RELATIVE = 2;
const NUM_OWNER_NEIGHBOUR = 3;

const types = [
  { key: 0, name: "El titular" },
  { key: 1, name: "Guardia de seguridad" },
  { key: 2, name: "Pariente del titular" },
  { key: 3, name: "Vecino del titular" }
];

export default {
  OWNER,
  SECURITY_GUARD,
  OWNER_RELATIVE,
  OWNER_NEIGHBOUR,
  NUM_OWNER,
  NUM_SECURITY_GUARD,
  NUM_OWNER_RELATIVE,
  NUM_OWNER_NEIGHBOUR,
  types
};
