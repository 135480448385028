<template>
  <div class="collapsable-card border rounded">
    <div class="d-flex justify-content-between align-items-start w-100">
      <div class="d-flex align-items-center w-100">
        <div class="grid-destinations w-100" @click="toggle()">
          <font-awesome-icon
            icon="hexagon-bullet"
            class="g-icon"
            :class="{ 'blue-icon': !origin }"
          />
          <div class="my-auto g-name">
            {{ destination.name }}
          </div>

          <div class="mt-1 g-address ">
            {{ destination.address }}

            <quick-message
              :show.sync="hasError"
              with-icon
              class="mt-2"
              message="Error al validar la incidencia"
              type="error"
            />
          </div>

          <div v-if="hasIncidence" class="g-incidence">
            <b-popover
              :target="`incidence-btn-${destination.id}`"
              placement="top"
            >
              <div v-on-clickaway="hideIncidence">
                <p class="title">
                  Incidencias
                </p>
                <div
                  v-for="incidence in destination.incidences"
                  :key="`incidence-${incidence.id}`"
                >
                  <incidence-item
                    :incidence="incidence"
                    @hide-incidence="hideIncidence"
                    @validate-error="hasError = true"
                  />
                </div>
              </div>
            </b-popover>

            <div
              :id="`incidence-btn-${destination.id}`"
              v-tooltip.top-start="{
                content: 'Presiona para ver la incidencia'
              }"
              class="d-flex align-item-center justify-content-center h-100"
              @click.stop="showIncidence"
            >
              <font-awesome-icon icon="incidence" class="m-auto" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          id="collapse-btn"
          variant="secondary"
          class="text-dark"
          :class="{ 'point-up': visible }"
          @click="toggle"
        >
          <font-awesome-icon icon="chevron-down" />
        </div>
      </div>
    </div>
    <b-collapse id="collapsable-section" v-model="visible">
      <div class="info-container" @click="toggle()">
        <div class="d-flex">
          <div class="section">Contacto:</div>
          {{ destination.phone }}
        </div>

        <div v-if="destination.information" class="mt-2 d-flex">
          <p>
            <strong class="section">Referencias:</strong>
            {{ destination.information }}
          </p>
        </div>

        <!-- additional -->
        <div v-if="destination.additional" class="mt-2">
          <p
            v-if="hasExternalId"
            v-tooltip.top="tooltip"
            @click.stop="copyToClipboard"
          >
            <strong class="section"> Identificador externo:</strong>
            {{ destination.additional.externalId }}
          </p>

          <!-- TODO: Use this parameters when back return it -->
          <!-- <p>
            <strong class="section">Artículos restringidos:</strong>
            Si
          </p>

          <p class="d-flex">
            <strong class="section">URL de ratreo</strong>
            <a target="_blank" href="www.zubut.com">
              https://app.zubut.com/tracking/package/
            </a>
          </p> -->
        </div>

        <div v-show="hasGuide">
          <b-button
            variant="link"
            class="px-0"
            size="sm"
            @click.stop="$emit('show-guide', destination.id)"
          >
            Ver guía
          </b-button>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import * as Clipboardy from "clipboardy";
import { mixin as clickaway } from "vue-clickaway";
import IncidenceItem from "@/app/components/IncidenceItem";

export default {
  name: "CollapsableDestination",

  components: { IncidenceItem },

  mixins: [clickaway],

  props: {
    destination: {
      type: Object,
      required: true
    },
    hasGuide: {
      type: Boolean,
      default: false
    },
    origin: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      visible: false,
      loading: false,
      hasError: false,
      tooltip: "Copiar"
    };
  },

  computed: {
    hasExternalId() {
      return this.destination?.additional?.externalId ?? false;
    },

    hasIncidence() {
      return (
        this.destination.incidences &&
        this.destination.incidences.length > 0 &&
        this.destination.incidences.some(incidence => !incidence.accepted)
      );
    }
  },

  methods: {
    toggle() {
      this.visible = !this.visible;
    },

    copyToClipboard() {
      Clipboardy.write(this.destination?.additional.externalId ?? "");
      this.tooltip = "Copiado";
      setTimeout(() => {
        this.tooltip = "Copiar";
      }, 1500);
    },

    showIncidence() {
      this.$root.$emit("bv::show::popover", "my-trigger-button-id");
    },

    hideIncidence() {
      this.$root.$emit("bv::hide::popover");
    }
  }
};
</script>

<style lang="scss" scoped>
.popover {
  min-width: 350px;
  padding: 8px 16px;
  position: relative;
  background-color: $white;
  border: none;
  box-shadow: 0 3px 6px 0 rgba($black, 0.16);

  p {
    margin: 0;
  }

  .title {
    font-size: 14px;
    font-weight: 600;
  }
}

.collapsable-card {
  &:hover {
    border-color: var(--primary) !important;
  }
}

#collapse-btn {
  cursor: pointer;
  margin: 6px 12px 12px 0px;
  padding: 0.5em 1em;

  &:hover {
    background-color: $white-smoke;
  }

  &.point-up svg {
    transform: rotate(180deg);
  }

  svg {
    transition: transform 0.3s ease;
  }
}

#incidence-btn {
  cursor: pointer;
  border-radius: 6px;

  padding: 4px 4px;

  &:hover {
    background-color: $tutu;
  }
}

.grid-destinations {
  display: grid;
  grid-template-columns: 36px auto 28px;
  grid-template-rows: auto auto;

  padding: 12px 4px 12px 12px;
  cursor: pointer;

  .g-icon {
    grid-column: 1;
    grid-row: 1;

    font-size: 24px;
  }

  .g-name {
    grid-column: 2;
    grid-row: 1;

    font-size: 12px;
    font-weight: 600;
  }

  .g-address {
    grid-column: 2;
    grid-row: 2;

    font-size: 12px;
  }

  .g-incidence {
    grid-column: 3;
    grid-row: 1;

    font-size: 18px;
    color: $gamboge;
  }
}

.blue-icon {
  color: var(--primary);
}

#collapsable-section {
  .info-container {
    padding: 0px 12px 12px 12px;

    margin: 0 36px;
    font-size: 12px;
    cursor: pointer;

    p {
      margin: 0;
    }

    .section {
      font-weight: 600;
      margin-right: 6px;
    }
  }
}
</style>
