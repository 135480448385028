const DESTINATION_ERROR = "El mensajero firmó lejos del destino"; // Error en el destino
const DRIVER_WITHOUT_POSITION = "Mensajero sin coordenadas";
const DRIVER_CHECKIN_AWAY_FROM_ORIGIN =
  "El mensajero intentó firmar lejos del origen";

const NUM_DESTINATION_ERROR = 1;
const NUM_DRIVER_WITHOUT_POSITION = 2;
const NUM_DRIVER_CHECKIN_AWAY_FROM_ORIGIN = 3;

const get = {};
get[NUM_DESTINATION_ERROR] = DESTINATION_ERROR;
get[NUM_DRIVER_WITHOUT_POSITION] = DRIVER_WITHOUT_POSITION;
get[NUM_DRIVER_CHECKIN_AWAY_FROM_ORIGIN] = DRIVER_CHECKIN_AWAY_FROM_ORIGIN;

export default {
  get,
  NUM_DESTINATION_ERROR,
  NUM_DRIVER_WITHOUT_POSITION,
  NUM_DRIVER_CHECKIN_AWAY_FROM_ORIGIN
};
