<template>
  <div class="service-logs border rounded p-4">
    <div class="title font-weight-bold h6 mb-3">
      Registros de viaje
    </div>
    <package-service-logs
      v-if="service.type === serviceType.NUM_PACKAGE_DELIVERY"
      :service="service"
    />
    <multipoint-service-logs
      v-else
      :service="service"
      @cancel-schedule-service="$emit('cancel-schedule-service')"
    />
  </div>
</template>

<script>
import MultipointServiceLogs from "./MultipointServiceLogs";
import PackageServiceLogs from "./PackageServiceLogs";
import ServiceType from "@zubut/common/src/constants/services/type";

export default {
  name: "ServiceLogs",

  components: {
    MultipointServiceLogs,
    PackageServiceLogs
  },

  props: {
    service: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      serviceType: ServiceType
    };
  }
};
</script>
