<template>
  <b-modal
    :visible="show"
    centered
    hide-footer
    modal-class="shipping-label-modal"
    header-class="pt-3 pb-0 border-0"
    @change="toggleModal"
    @shown="openedModal"
    @hidden="hidModal"
  >
    <loading-spinner v-if="loading"></loading-spinner>
    <div v-else>
      <div class="border position-relative text-center">
        <img :src="shippingLabel" />
        <div id="btn-download" class="position-absolute">
          <z-button
            v-tooltip.bottom="'Exportar'"
            variant="secondary"
            fit="auto"
            class="text-dark mr-3 mb-0 h6"
            @click="downloadFile"
          >
            <font-awesome-icon icon="download" />
          </z-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Destinations from "@/services/destinations";

export default {
  name: "ShippingLabelModal",

  props: {
    destinationId: {
      type: String,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      loading: true,
      shippingLabel: null
    };
  },

  methods: {
    openedModal() {
      this.getShippingLabel();
    },
    hidModal() {
      this.loading = true;
      this.shippingLabel = null;
    },
    toggleModal(show) {
      this.$emit("update:show", show);
    },
    getShippingLabel() {
      this.loading = true;
      Destinations.shippingLabel(this.destinationId)
        .then(({ base64Label }) => {
          this.shippingLabel = `data:image/png;base64,${base64Label}`;
        })
        .catch(error => {
          this.$captureError(error);
        })
        .finally(() => (this.loading = false));
    },
    downloadFile(guide) {
      let a = document.createElement("a");
      a.href = this.shippingLabel;
      a.download = `guia-${this.destinationId}.png`;
      a.click();
    }
  }
};
</script>

<style lang="scss">
.shipping-label-modal {
  #btn-download {
    top: 1em;
    right: 0px;
  }
}
</style>
