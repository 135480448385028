<template>
  <div class="z-service-package-logs">
    <b-list-group flush class="border-bottom">
      <b-list-group-item>
        <div>
          Servicio requerido y creado con éxito
        </div>
        <div class="text-capitalize">
          {{ creationTime }}
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div>
          Paquetes asignados
        </div>
        <div>
          {{ assignedPackages }}
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div>
          Paquetes entregados
        </div>
        <div>
          {{ deliveredPackages }}
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { formatISO } from "@zubut/common/src/utils/time";

export default {
  name: "PackageServiceLogs",

  props: {
    service: {
      type: Object,
      required: true
    }
  },

  computed: {
    assignedPackages() {
      if (this.service.additional && this.service.additional.assigned) {
        return this.service.additional.assigned;
      }
      return "N/A";
    },
    creationTime() {
      return formatISO(this.service.createdAt, "MMMM dd, yyyy h:mm a");
    },
    deliveredPackages() {
      if (this.service.additional && this.service.additional.deliveries) {
        return this.service.additional.deliveries;
      }
      return "N/A";
    }
  }
};
</script>

<style lang="scss" scoped>
.z-service-package-logs {
  .list-group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    padding: 0.75rem 0rem;
  }
}
</style>
