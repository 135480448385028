<template>
  <div class="container-destinations">
    <p class="text title">Origen</p>
    <collapsable-destination origin :destination="origin" />
    <div v-show="hasDeliveries">
      <p class="title mt-3">Destinos</p>
      <div
        v-for="(destination, index) in deliveries"
        :key="`d-${destination.id}`"
      >
        <collapsable-destination
          :destination="destination"
          :class="[{ 'mt-3': index != 0 }]"
          :has-guide="isBatchService"
          @show-guide="showGuide"
        />
      </div>
    </div>
    <shipping-label-modal
      :show.sync="showShippingLabel"
      :destination-id="destinationId"
    />
  </div>
</template>

<script>
import CollapsableDestination from "./CollapsableDestination";
import ServiceType from "@zubut/common/src/constants/services/type";
import ShippingLabelModal from "./ShippingLabelModal";

export default {
  name: "ServiceDetailDestinations",

  components: { CollapsableDestination, ShippingLabelModal },

  props: {
    destinations: {
      type: Array,
      default: () => {
        return [];
      }
    },
    serviceType: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      destinationId: "",
      showShippingLabel: false
    };
  },

  computed: {
    isBatchService() {
      return this.serviceType === ServiceType.NUM_BATCH;
    },

    origin() {
      const origin = this.destinations.find(d => d.order === 0);
      if (origin != null) {
        return origin;
      } else {
        return {};
      }
    },

    deliveries() {
      return this.destinations.filter(d => d.order != 0);
    },

    hasDeliveries() {
      return this.deliveries.length > 0;
    }
  },

  methods: {
    showGuide(destinationId) {
      this.destinationId = destinationId;
      this.showShippingLabel = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.container-destinations {
  padding: 8px 0;
  color: $nero;

  .title {
    font-size: 14px;
    font-weight: 600;
  }
}
</style>
