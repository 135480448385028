<template>
  <b-modal
    ref="confirmDialog"
    v-bind="$attrs"
    class="z-confirm-dialog"
    :centered="centered"
    :title="title"
    @hidden="$emit('hidden')"
  >
    <slot> </slot>
    <template v-slot:modal-footer class="w-100 d-print-none">
      <div>
        <z-button
          data-test-id="accept-btn"
          class="float-right ml-3"
          :disabled="acceptDisable"
          @click="$emit('accept')"
        >
          {{ acceptText }}
        </z-button>
        <z-button
          data-test-id="cancel-btn"
          variant="secondary"
          class="float-right btn-cancel"
          :disabled="cancelDisable"
          @click="$emit('cancel')"
        >
          {{ cancelText }}
        </z-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "ConfirmationDialog",
  props: {
    acceptText: {
      type: String,
      default: "Aceptar"
    },
    acceptDisable: {
      type: Boolean,
      default: false
    },
    cancelText: {
      type: String,
      default: "Cancelar"
    },
    cancelDisable: {
      type: Boolean,
      default: false
    },
    centered: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "Mensaje de confirmación"
    }
  },
  watch: {
    show(newShow) {
      if (newShow === true) {
        this.showModal();
      } else {
        this.hideModal();
      }
    }
  },
  methods: {
    hideModal() {
      this.$refs.confirmDialog.hide();
    },
    showModal() {
      this.$refs.confirmDialog.show();
    }
  }
};
</script>
