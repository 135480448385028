<template>
  <div>
    <div class="border rounded p-4">
      <service-payment-detail
        :is-account-user="isAccountUser"
        :is-consolidated-user="isConsolidatedUser"
        :is-billed-service="isBilledService"
        :service="service"
        :service-brand="service.brand"
        :service-card="service.card"
        :service-wallet-id="service.walletId"
        :service-city="service.city"
        @reload="$emit('reload')"
      />
    </div>
    <resume-stats
      class="mt-4"
      :kms="service.km"
      :estimated-time="service.estimatedTime"
      :time="service.serviceTime"
    />
    <resume-review class="mt-4" :ratings="service.ratings" />
  </div>
</template>

<script>
import ResumeReview from "./ResumeReview";
import ResumeStats from "./ResumeStats";
import ServiceBillingStatus from "@/constants/services/billing-status";
import ServicePaymentDetail from "@/app/components/ServicePaymentDetail/ServicePaymentDetail";

export default {
  name: "ServiceDetailTabsResume",

  components: {
    ResumeReview,
    ResumeStats,
    ServicePaymentDetail
  },

  props: {
    service: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isAccountUser: this.$store.getters["user/isAccountUser"],
      isConsolidatedUser: this.$store.getters["user/isConsolidatedUser"]
    };
  },

  computed: {
    isBilledService() {
      return this.service.billingStatus === ServiceBillingStatus.NUM_INVOICED;
    }
  }
};
</script>
