<template>
  <div class="z-service-normal-logs">
    <div
      v-if="isScheduledService && !isRentPerHourService"
      class="scheduled-service-register border-bottom"
    >
      <div class="text-left">
        <div>
          Se espera el servicio
          <b>{{ scheduledServiceDate }}</b> a las
          <b>{{ scheduledServiceTime }}</b> horas.
        </div>
      </div>
      <div class="text-right">
        <div>
          <a class="cancel-scheduled" @click="$emit('cancel-schedule-service')">
            Cancelar Servicio
          </a>
        </div>
      </div>
    </div>
    <b-list-group flush>
      <b-list-group-item
        v-if="isRentPerHourService"
        class="py-3 d-flex justify-content-between"
      >
        <div>
          <div><span class="text-muted">Hora de reservación:</span></div>
          <div>
            {{ rentServiceDate }} ({{ rentServiceStart }} -
            {{ rentServiceEnd }})
          </div>
        </div>
        <div>
          <div><span class="text-muted">Duración:</span></div>
          <div class="text-right">{{ rentServiceDuration }} hrs.</div>
        </div>
      </b-list-group-item>
      <b-list-group-item>
        Servicio requerido y creado con éxito
        <div class="text-right">
          {{ creationTime }}
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="!isRentPerHourService && acceptedTime">
        <span>
          Tu viaje fue aceptado por el mensajero
        </span>
        <div>
          {{ acceptedTime }}
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="isRentPerHourService && checkInTime">
        <span>
          Check-in
        </span>
        <div>
          {{ checkInTime }}
        </div>
      </b-list-group-item>
      <b-list-group-item
        v-for="(stop, index) in serviceStops"
        :key="stop.order"
        class="d-flex flex-column align-items-stretch"
      >
        <div class="d-flex flex-row justify-content-between">
          <div>El mensajero llegó a {{ destinations[index].name }}</div>
          <div>
            {{ stop.arrived }}
          </div>
        </div>
        <div class="d-flex flex-row justify-content-between">
          Firmado en {{ destinations[index].name }}
          <div>
            {{ stop.delivered }}
          </div>
        </div>
      </b-list-group-item>
      <b-list-group-item
        v-for="dest in destinations.filter(el => el.signed)"
        :key="dest.id"
      >
        Firma realizada por {{ dest.name }}
        <div>
          {{ dest.signed }}
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="!isRentPerHourService && isSuccessfulService">
        Paquete entregado y servicio exitosamente finalizado
        <div class="text-right">
          {{ finishedTime }}
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="isRentPerHourService && isSuccessfulService">
        Check-out
        <div>
          {{ checkOutTime }}
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="isCanceledByClientService">
        Cancelaste exitosamente tu servicio
        <div>
          {{ finishedTime }}
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="isCanceledByZubutService">
        Tu servicio fue cancelado por Zubut
        <div>
          {{ finishedTime }}
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="isCanceledByDriverService">
        Tu servicio fue cancelado por el mensajero
        <div>
          {{ finishedTime }}
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="haveAdditionalHours">
        {{
          `Solicitó ${
            additionalHours === 1
              ? additionalHours + " hr"
              : additionalHours + " hrs"
          } extra`
        }}
        <div>
          {{ additionalHoursMessage }}
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import ServiceStatus from "@/constants/services/status";
import AdditionalHours from "@zubut/common/src/constants/services/additional-hours";
import {
  differenceInHours,
  fromUnixTime,
  isToday,
  isTomorrow,
  parseISO
} from "date-fns";
import { format } from "@zubut/common/src/utils/time";

export default {
  name: "MultipointServiceLogs",

  props: {
    service: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      additionalHours: 0,
      additionalHoursStatus: 0
    };
  },

  computed: {
    acceptedTime() {
      if (this.service.resume && this.service.resume.acceptedAt) {
        return format(parseISO(this.service.resume.acceptedAt), "h:mm a");
      }
      return "";
    },

    additionalOptions() {
      if (this.service && this.service.additional) {
        return this.service.additional;
      }
      return {};
    },

    checkInTime() {
      if (this.service.resume && this.service.resume.checkIn) {
        return format(parseISO(this.service.resume.checkIn), "h:mm a");
      }
      return "";
    },

    checkOutTime() {
      if (this.service.resume && this.service.resume.finishedAt) {
        return format(parseISO(this.service.resume.finishedAt), "h:mm a");
      }
      return "";
    },

    checkPoints() {
      return this.service.resume.checkPoints;
    },

    creationTime() {
      return format(
        parseISO(this.service.createdAt),
        "d 'de' MMM. 'de' yyyy h:mm a"
      );
    },

    destinations() {
      if (
        !ServiceStatus.CANCELLED_STATUS.includes(this.service.status) &&
        this.service.resume &&
        this.service.resume.signatures
      ) {
        const destinations = [...this.service.destinations];
        this.service.resume.signatures.forEach((signature, index) => {
          destinations[index].signed = format(
            parseISO(signature.time),
            "h:mm a"
          );
        });
        return destinations;
      }
      return this.service.destinations;
    },

    finishedTime() {
      let date = "";
      if (this.service.cancelledAt) {
        date = this.service.cancelledAt;
      } else if (this.service.resume && this.service.resume.finishedAt) {
        date = this.service.resume.finishedAt;
      }
      return date ? format(parseISO(date), "d 'de' MMM. 'de' yyyy h:mm a") : "";
    },

    isScheduledService() {
      return this.service.status === ServiceStatus.NUM_SCHEDULED;
    },

    isSuccessfulService() {
      return (
        this.finishedTime && this.service.status === ServiceStatus.NUM_FINISHED
      );
    },

    isCanceledByClientService() {
      return (
        this.finishedTime &&
        this.service.status === ServiceStatus.NUM_CLIENT_CANCELLED
      );
    },

    isCanceledByZubutService() {
      return (
        this.finishedTime &&
        this.service.status === ServiceStatus.NUM_ZUBUT_CANCELLED
      );
    },

    isCanceledByDriverService() {
      return (
        this.finishedTime &&
        this.service.status === ServiceStatus.NUM_DRIVER_CANCELLED
      );
    },

    isRentPerHourService() {
      return (
        this.service.reservationBlock &&
        this.service.reservationBlock.id.length > 0
      );
    },

    rentServiceDuration() {
      if (this.isRentPerHourService) {
        const { end, start } = this.service.reservationBlock;
        return differenceInHours(parseISO(end), parseISO(start));
      }
      return "";
    },

    rentServiceDate() {
      if (this.isRentPerHourService) {
        return format(
          parseISO(this.service.reservationBlock.start),
          "d 'de' MMM. 'de' yyyy"
        );
      }
      return "";
    },

    rentServiceEnd() {
      if (this.isRentPerHourService) {
        return format(parseISO(this.service.reservationBlock.end), "h:mm a");
      }
      return "";
    },

    rentServiceStart() {
      if (this.isRentPerHourService) {
        return format(parseISO(this.service.reservationBlock.start), "h:mm a");
      }
      return "";
    },

    scheduledAt() {
      if (this.isScheduledService && !this.isRentPerHourService) {
        return fromUnixTime(this.additionalOptions.scheduled);
      }
      return "";
    },

    scheduledServiceDate() {
      if (this.isScheduledService && !this.isRentPerHourService) {
        const isSameDay = isToday(this.scheduledAt);
        const isNextDay = isTomorrow(this.scheduledAt);
        const scheduledDay = format(this.scheduledAt, "d 'de' MMM. 'de' yyyy");
        let serviceDate = "";
        if (isSameDay) {
          serviceDate = "Hoy";
        } else if (isNextDay) {
          serviceDate = "Mañana";
        } else {
          serviceDate = `el día ${scheduledDay}`;
        }
        return serviceDate;
      }
      return "";
    },

    scheduledServiceTime() {
      return format(this.scheduledAt, "h:mm a");
    },

    serviceStops() {
      if (this.service.resume && this.service.resume.checkPoints) {
        return this.service.resume.checkPoints.map(item => ({
          order: item.order,
          arrived: format(parseISO(item.arrived), "h:mm a"),
          delivered: item.delivered
            ? format(parseISO(item.delivered), "h:mm a")
            : ""
        }));
      }
      return [];
    },

    haveAdditionalHours() {
      if (this.service.additionalHoursRequest) this.getAdditionalHours();
      return this.service.additionalHoursRequest;
    },

    additionalHoursMessage() {
      return AdditionalHours.get[this.additionalHoursStatus];
    }
  },

  methods: {
    getAdditionalHours() {
      this.additionalHours = this.service.additionalHoursRequest.additionalHours;
      this.additionalHoursStatus = this.service.additionalHoursRequest.status;
    }
  }
};
</script>

<style lang="scss" scoped>
.z-service-normal-logs {
  font-size: 12px;

  .list-group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 0rem;
  }

  .scheduled-service-register {
    padding: 0.75rem 0rem;
    display: flex;
    justify-content: space-between;

    .cancel-scheduled {
      color: $alizarin;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .z-service-normal-logs {
    font-size: 12px;

    .scheduled-service-register {
      padding: 0.75rem 0rem;
    }
  }
}
</style>
