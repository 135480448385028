import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("Destinations");

const Destinations = Object.assign(
  {},
  {
    relation: "",

    shippingLabel(destinationId) {
      return loopback.get("/Destinations/shippingLabel", {
        params: { destinationId, format: "png" }
      });
    },

    trackingInfo({ trackingId }) {
      return loopback.get("/Destinations/trackingInfo", {
        params: { trackingId }
      });
    }
  },
  modelObject
);

export default Destinations;
