const NUM_RETURN_CLIENT_NOT_FOUND = 1;
const NUM_RETURN_DAMAGE_PACKAGE = 2;
const NUM_RETURN_WRONG_PACKAGE = 3;

const RETURN_CLIENT_NOT_FOUND = "Cliente no localizado";
const RETURN_DAMAGE_PACKAGE = "Paquete dañado";
const RETURN_WRONG_PACKAGE = "Paquete equivocado";

const get = {};
get[NUM_RETURN_CLIENT_NOT_FOUND] = RETURN_CLIENT_NOT_FOUND;
get[NUM_RETURN_DAMAGE_PACKAGE] = RETURN_DAMAGE_PACKAGE;
get[NUM_RETURN_WRONG_PACKAGE] = RETURN_WRONG_PACKAGE;

export default {
  get,
  NUM_RETURN_CLIENT_NOT_FOUND,
  NUM_RETURN_DAMAGE_PACKAGE,
  NUM_RETURN_WRONG_PACKAGE
};
