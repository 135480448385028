import ServiceCancelReason from "@/constants/services/cancel-reason";
import ServiceStatus from "@/constants/services/status";
import ServicePaymentStatus from "@/constants/services/payment-status";
import ServiceBillingStatus from "@/constants/services/billing-status";

export function getCombinedStatusName({
  status,
  paymentStatus,
  billingStatus,
  cancelReason
}) {
  if (cancelReason === ServiceCancelReason.NUM_DRIVERS_NOT_AVAILABLE) {
    return ServiceCancelReason.DRIVERS_NOT_AVAILABLE;
  }
  if (status === ServiceStatus.NUM_FINISHED) {
    if (
      billingStatus === ServiceBillingStatus.INVOICED ||
      billingStatus === ServiceBillingStatus.NUM_INVOICE_ERROR
    ) {
      return ServiceBillingStatus.get[billingStatus];
    }
    const prefix =
      paymentStatus === ServicePaymentStatus.NUM_NOT_PAID
        ? "Completado / "
        : "";
    return prefix + ServicePaymentStatus.get[paymentStatus];
  }
  return ServiceStatus.get[status];
}

export function getCombinedStatusColorVariant({
  status,
  paymentStatus,
  billingStatus,
  cancelReason
}) {
  let classType = "secondary";
  if (
    cancelReason === ServiceCancelReason.NUM_DRIVERS_NOT_AVAILABLE ||
    status === ServiceStatus.NUM_DRIVERS_NOT_AVAILABLE
  )
    return classType;
  if (
    status === ServiceStatus.NUM_SCHEDULED ||
    billingStatus === ServiceBillingStatus.NUM_INVOICED ||
    status === ServiceStatus.NUM_CREATED ||
    paymentStatus === ServicePaymentStatus.NUM_CREDIT
  )
    classType = "primary";
  if (
    status === ServiceStatus.NUM_ACTIVE ||
    status === ServiceStatus.NUM_REQUESTING ||
    (paymentStatus === ServicePaymentStatus.NUM_PAID &&
      status !== ServiceStatus.NUM_CREATED &&
      billingStatus !== ServiceBillingStatus.NUM_INVOICED)
  )
    classType = "success";
  if (
    (status === ServiceStatus.NUM_FINISHED &&
      paymentStatus === ServicePaymentStatus.NUM_NOT_PAID) ||
    billingStatus === ServiceBillingStatus.NUM_INVOICE_ERROR
  )
    classType = "warning";
  if (ServiceStatus.CANCELLED_STATUS.includes(status)) classType = "danger";
  return classType;
}

export function getPaymentStatusColorVariant(paymentStatus) {
  switch (paymentStatus) {
    case ServicePaymentStatus.NUM_CREDIT:
      return "primary";
    case ServicePaymentStatus.NUM_PAID:
    case ServicePaymentStatus.NUM_REIMBURSED:
      return "success";
    case ServicePaymentStatus.NUM_NOT_PAID:
      return "warning";
    default:
      return "secondary";
  }
}

export function getStatusName(status, cancelReason) {
  if (cancelReason === ServiceCancelReason.NUM_DRIVERS_NOT_AVAILABLE) {
    return ServiceCancelReason.DRIVERS_NOT_AVAILABLE;
  }
  return ServiceStatus.get[status];
}

export function getStatusColorVariant(status, cancelReason) {
  if (cancelReason === ServiceCancelReason.NUM_DRIVERS_NOT_AVAILABLE)
    return "secondary";
  switch (status) {
    case ServiceStatus.NUM_CREATED:
    case ServiceStatus.NUM_SCHEDULED:
      return "primary";
    case ServiceStatus.NUM_REQUESTING:
    case ServiceStatus.NUM_ACTIVE:
    case ServiceStatus.NUM_FINISHED:
      return "success";
    case ServiceStatus.NUM_CLIENT_CANCELLED:
    case ServiceStatus.NUM_ZUBUT_CANCELLED:
    case ServiceStatus.NUM_DRIVER_CANCELLED:
      return "danger";
    default:
      return "secondary";
  }
}
