const NUM_PENDING_HOURS = 0;
const NUM_ACCEPTED_HOURS = 1;
const NUM_REJECTED_HOURS = 2;

const PENDING_HOURS = "Pendiente";
const ACCEPTED_HOURS = "Aceptado";
const REJECTED_HOURS = "Rechazado";

const get: any = {};
get[NUM_PENDING_HOURS] = PENDING_HOURS;
get[NUM_ACCEPTED_HOURS] = ACCEPTED_HOURS;
get[NUM_REJECTED_HOURS] = REJECTED_HOURS;

const obj: any = {
  get,
  NUM_PENDING_HOURS,
  NUM_ACCEPTED_HOURS,
  NUM_REJECTED_HOURS
};

export default obj;
