<template>
  <div class="z-service-package-detail">
    <b-row>
      <b-col
        class="d-flex justify-content-between align-items-end pb-3 font-weight-semi-bold"
      >
        <div class="h5 font-weight-bold">Total</div>
        <div class="h4 mb-0">{{ totalFormatted }}</div>
      </b-col>
    </b-row>
    <b-list-group flush class="list border-top">
      <b-list-group-item class="px-0 py-3 d-flex justify-content-between">
        <div>
          Precio por/paquete
        </div>
        <div>{{ deliveredPackages }} x {{ costPerPackageFormatted }}</div>
      </b-list-group-item>
      <b-list-group-item class="px-0 py-3 d-flex justify-content-between">
        <div>
          Subtotal
        </div>
        <div>{{ subtotalFormatted }}</div>
      </b-list-group-item>
      <b-list-group-item class="px-0 py-3 d-flex justify-content-between">
        <div>{{ $t("tax") }} (16%)</div>
        <div>{{ taxFormatted }}</div>
      </b-list-group-item>
      <slot name="custom-items"></slot>
    </b-list-group>
  </div>
</template>

<script>
import { formatMoney } from "@/utils/money";

export default {
  name: "PackageServicePaymentDetail",

  props: {
    service: {
      type: Object,
      required: true
    }
  },

  computed: {
    costPerPackage() {
      if (this.deliveredPackages) {
        return this.subtotal / this.deliveredPackages;
      }
      return 0;
    },

    costPerPackageFormatted() {
      return formatMoney(this.costPerPackage);
    },

    deliveredPackages() {
      if (this.service.additional && this.service.additional.deliveries) {
        return this.service.additional.deliveries;
      }
      return 0;
    },

    subtotal() {
      return this.service?.paymentDetails?.subtotal ?? null;
    },

    subtotalFormatted() {
      if (this.subtotal !== null) {
        return formatMoney(this.subtotal);
      }
      return "";
    },

    tax() {
      return this.service?.paymentDetails?.tax ?? null;
    },

    taxFormatted() {
      if (this.tax !== null) {
        return formatMoney(this.tax);
      }
      return "";
    },

    total() {
      return this.service?.paymentDetails?.total ?? null;
    },

    totalFormatted() {
      if (this.total !== null) {
        return formatMoney(this.total);
      }
      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
.z-service-package-detail {
  .list-group-item {
    font-size: 13px;
  }
}
</style>
