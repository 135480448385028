<template>
  <div class="text-center">
    <p class="mb-0">Ocurrió un error de conexión</p>
    <b-button variant="link" @click="reloadPage">Recargar</b-button>
  </div>
</template>

<script>
export default {
  name: "ErrorComponent",
  methods: {
    reloadPage() {
      window.location.reload();
    }
  }
};
</script>
