<template>
  <b-list-group-item
    v-if="showPaymentMethod"
    class="payment-method-item px-0 d-flex justify-content-between align-items-center"
  >
    <span>Método de pago:</span>
    <div v-if="isPayedWithCreditCard" class="d-flex align-items-center">
      <div>
        <amex-icon
          v-if="
            creditCardBrand === 'AMEX' || creditCardBrand === 'AMERICAN_EXPRESS'
          "
          class="credit-card-icon"
        />
        <mastercard-icon
          v-else-if="creditCardBrand === 'MC'"
          class="credit-card-icon"
        />
        <visa-icon
          v-else-if="creditCardBrand === 'VISA'"
          class="credit-card-icon"
        />
        <font-awesome-icon v-else icon="credit-card" size="lg" />
      </div>
      <div class="ml-2">
        {{ cardInfo }}
      </div>
    </div>
    <span v-if="isPayedWithWallet">
      Wallet
    </span>
  </b-list-group-item>
</template>

<script>
import AmexIcon from "@/assets/icons/ic_amex.svg";
import MastercardIcon from "@/assets/icons/ic_mastercard.svg";
import VisaIcon from "@/assets/icons/ic_visa.svg";

export default {
  name: "PaymentMethodItem",

  components: {
    AmexIcon,
    MastercardIcon,
    VisaIcon
  },

  props: {
    service: {
      type: Object,
      required: true
    }
  },

  computed: {
    brandFullName() {
      switch (this.creditCardBrand) {
        case "AMEX":
        case "AMERICAN_EXPRESS":
          return "American express";
        case "MC":
          return "Mastercard";
        case "VISA":
          return "Visa";
        default:
          return "";
      }
    },

    cardInfo() {
      const last4 = this.service?.cardInfo?.last4;
      if (last4) {
        return `${this.brandFullName} **** ${last4}`;
      }
      return this.brandFullName;
    },

    creditCardBrand() {
      const brand = this.service?.cardInfo?.brand ?? "";
      return brand.toUpperCase();
    },

    isPayedWithWallet() {
      return this.service.walletId != null;
    },

    isPayedWithCreditCard() {
      return this.service.cardInfo != null;
    },

    showPaymentMethod() {
      return this.isPayedWithWallet || this.isPayedWithCreditCard;
    }
  }
};
</script>

<style lang="scss" scoped>
.payment-method-item {
  font-size: 12px;

  .credit-card-icon {
    width: 24px;
  }
}
</style>
