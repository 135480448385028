const NUM_CLIENT_TOOK_A_LONG_TIME = 1;
const NUM_CLIENT_CONTACTED = 3;

const CLIENT_TOOK_A_LONG_TIME =
  "El cliente tardó demasiado en recoger/entregar";
const CLIENT_CONTACTED = "Se intentó contactar al cliente";

const get = {};
get[NUM_CLIENT_TOOK_A_LONG_TIME] = CLIENT_TOOK_A_LONG_TIME;
get[NUM_CLIENT_CONTACTED] = CLIENT_CONTACTED;

export default {
  get
};
