const CREDIT = "Crédito";
const NOT_PAID = "Por pagar";
const PAID = "Pagado";
const REIMBURSED = "Reembolsado";
const NOT_APPLICABLE = "No aplica";

const NUM_CREDIT = 0;
const NUM_NOT_PAID = 1;
const NUM_PAID = 2;
const NUM_REIMBURSED = 3;
const NUM_NOT_APPLICABLE = 4;

const get = {};
get[NUM_CREDIT] = CREDIT;
get[NUM_NOT_PAID] = NOT_PAID;
get[NUM_PAID] = PAID;
get[NUM_REIMBURSED] = REIMBURSED;
get[NUM_NOT_APPLICABLE] = NOT_APPLICABLE;

export default {
  get,
  NUM_CREDIT,
  NUM_NOT_PAID,
  NUM_PAID,
  NUM_REIMBURSED,
  NUM_NOT_APPLICABLE,
  CREDIT,
  NOT_PAID,
  PAID,
  REIMBURSED,
  NOT_APPLICABLE
};
