const NUM_SERVICE_NOT_PAID = 1;
const NUM_SERVICE_COMMENT = 2;
const NUM_SERVICE_LATE_CHECKIN = 3;

const SERVICE_NOT_PAID = "El cobro del pedido no exitoso";
const SERVICE_COMMENT = "Registro de comentario";
const SERVICE_LATE_CHECKIN = "Check-in a destiempo";

const get = {};
get[NUM_SERVICE_NOT_PAID] = SERVICE_NOT_PAID;
get[NUM_SERVICE_COMMENT] = SERVICE_COMMENT;
get[NUM_SERVICE_LATE_CHECKIN] = SERVICE_LATE_CHECKIN;

export default {
  get
};
