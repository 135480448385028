import LoadingSpinner from "@zubut/common/src/components/LoadingSpinner";
import ErrorComponent from "./ErrorComponent";

export default function(component) {
  return {
    component,
    loading: LoadingSpinner,
    error: ErrorComponent,
    timeout: 8000
  };
}
