<template>
  <div class="title-container">
    <div class="d-flex flex-column flex-md-row justify-content-between">
      <div class="d-flex">
        <div class="back-arrow d-inline" @click="$emit('back-action')">
          <arrow-left-icon />
        </div>
        <div class="mx-3">
          <div class="z-card-title">{{ title }}</div>
          <div class="subtitle">
            <div v-if="withAction" class="action" @click="$emit('back-action')">
              Regresar
            </div>
            <div v-else>{{ subtitle }}</div>
          </div>
        </div>
      </div>
      <div v-if="$scopedSlots['end']">
        <slot name="end"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowLeftIcon from "@/assets/icons/ic_arrow_left.svg";

export default {
  name: "GoBackTitle",

  components: {
    ArrowLeftIcon
  },

  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: ""
    },
    withAction: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.title-container {
  position: relative;

  .z-card-title {
    font-weight: 600;
    font-size: 18px;
  }

  .back-arrow {
    cursor: pointer;
    margin-top: 2px;
    svg {
      fill: var(--primary);
    }
  }

  .subtitle {
    width: auto;
    font-size: 12px;
    color: $dim-gray;

    .action {
      color: var(--primary);
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
