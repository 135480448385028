<template>
  <status-badge :status-name="statusName" :type="colorVariant" />
</template>

<script>
import StatusBadge from "./StatusBadge";
import ServiceCancelReason from "../constants/services/cancel-reason";
import ServiceStatus from "../constants/services/status";
import ServicePaymentStatus from "../constants/services/payment-status";
import ServiceBillingStatus from "../constants/services/billing-status";

export default {
  name: "StatusService",
  components: { StatusBadge },
  props: {
    status: {
      type: Number,
      default: -1
    },
    paymentStatus: {
      type: Number,
      required: false,
      default: -1
    },
    billingStatus: {
      type: Number,
      required: false,
      default: -1
    },
    cancelReason: {
      type: Number,
      required: false,
      default: -1
    },
    combined: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    statusName() {
      if (this.status >= 0) {
        return this.combined
          ? this.getCombinedStatusName({
              status: this.status,
              paymentStatus: this.paymentStatus,
              billingStatus: this.billingStatus,
              cancelReason: this.cancelReason
            })
          : this.getStatusName(this.status, this.cancelReason);
      } else return "";
    },
    colorVariant() {
      return this.combined
        ? this.getCombinedStatusColorVariant({
            status: this.status,
            paymentStatus: this.paymentStatus,
            billingStatus: this.billingStatus,
            cancelReason: this.cancelReason
          })
        : this.getStatusColorVariant(this.status, this.cancelReason);
    }
  },

  methods: {
    getCombinedStatusName({
      status,
      paymentStatus,
      billingStatus,
      cancelReason
    }) {
      if (cancelReason === ServiceCancelReason.NUM_DRIVERS_NOT_AVAILABLE) {
        return ServiceCancelReason.DRIVERS_NOT_AVAILABLE;
      }
      if (status === ServiceStatus.NUM_FINISHED) {
        if (
          billingStatus === ServiceBillingStatus.NUM_INVOICED ||
          billingStatus === ServiceBillingStatus.NUM_INVOICE_ERROR
        ) {
          return ServiceBillingStatus.get[billingStatus];
        }
        const prefix =
          paymentStatus === ServicePaymentStatus.NUM_NOT_PAID
            ? "Completado / "
            : "";
        return prefix + ServicePaymentStatus.get[paymentStatus];
      }
      return ServiceStatus.get[status];
    },

    getCombinedStatusColorVariant({
      status,
      paymentStatus,
      billingStatus,
      cancelReason
    }) {
      let classType = "yellow";
      if (
        cancelReason === ServiceCancelReason.NUM_DRIVERS_NOT_AVAILABLE ||
        status === ServiceStatus.NUM_DRIVERS_NOT_AVAILABLE
      )
        return classType;
      if (paymentStatus === ServicePaymentStatus.NUM_CREDIT) {
        classType = "blue";
      }
      if (
        status === ServiceStatus.NUM_SCHEDULED ||
        billingStatus === ServiceBillingStatus.NUM_INVOICED ||
        status === ServiceStatus.NUM_CREATED
      )
        classType = "green";
      if (
        status === ServiceStatus.NUM_ACTIVE ||
        status === ServiceStatus.NUM_REQUESTING ||
        (paymentStatus === ServicePaymentStatus.NUM_PAID &&
          status !== ServiceStatus.NUM_CREATED &&
          billingStatus !== ServiceBillingStatus.NUM_INVOICED)
      )
        classType = "green";
      if (
        (status === ServiceStatus.NUM_FINISHED &&
          paymentStatus === ServicePaymentStatus.NUM_NOT_PAID) ||
        status === ServiceStatus.NUM_REVIEW_PENDING ||
        (status === ServiceStatus.NUM_FINISHED &&
          paymentStatus === ServicePaymentStatus.NUM_NOT_APPLICABLE)
      )
        classType = "blue";
      if (
        ServiceStatus.CANCELLED_STATUS.includes(status) ||
        billingStatus === ServiceBillingStatus.NUM_INVOICE_ERROR
      )
        classType = "red";
      return classType;
    },

    getStatusName(status, cancelReason) {
      if (cancelReason === ServiceCancelReason.NUM_DRIVERS_NOT_AVAILABLE) {
        return ServiceCancelReason.DRIVERS_NOT_AVAILABLE;
      }
      return ServiceStatus.get[status];
    },

    getStatusColorVariant(status, cancelReason) {
      if (cancelReason === ServiceCancelReason.NUM_DRIVERS_NOT_AVAILABLE)
        return "yellow";
      switch (status) {
        case ServiceStatus.NUM_CREATED:
        case ServiceStatus.NUM_SCHEDULED:
          return "green";
        case ServiceStatus.NUM_REQUESTING:
        case ServiceStatus.NUM_ACTIVE:
        case ServiceStatus.NUM_FINISHED:
          return "green";
        case ServiceStatus.NUM_REVIEW_PENDING:
          return "yellow";
        case ServiceStatus.NUM_CLIENT_CANCELLED:
        case ServiceStatus.NUM_ZUBUT_CANCELLED:
        case ServiceStatus.NUM_DRIVER_CANCELLED:
          return "red";
        default:
          return "yellow";
      }
    }
  }
};
</script>
