const LIKE = "Like";
const DISLIKE = "Dislike";

const NUM_LIKE = 1;
const NUM_DISLIKE = 2;

const get = {};
get[NUM_LIKE] = LIKE;
get[NUM_DISLIKE] = DISLIKE;

export default {
  get,
  LIKE,
  DISLIKE,
  NUM_LIKE,
  NUM_DISLIKE
};
