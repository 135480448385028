<template>
  <div class="z-user-info" @click="$emit('click')">
    <div class="title">
      {{ title }}
    </div>
    <div class="d-flex">
      <z-profile-image size="sm" :type="type" :image="image" />
      <div class="ml-3">
        <div>
          {{ name }}
        </div>
        <div class="phone mt-1">
          {{ phone }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZProfileImage from "@/app/components/ZProfileImage";

const types = {
  USER: "user",
  DRIVER: "driver"
};

export default {
  name: "ZUserInfo",

  components: { ZProfileImage },

  props: {
    name: {
      type: String,
      required: true
    },
    phone: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: "user",
      validator: val => [types.USER, types.DRIVER].indexOf(val) > -1
    },
    image: {
      type: String,
      default: ""
    }
  },

  computed: {
    title() {
      return this.type === types.USER ? "Cliente" : "Mensajero";
    }
  }
};
</script>

<style lang="scss" scoped>
.z-user-info {
  font-size: 12px;

  .title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 1em;
  }

  .phone {
    color: rgba($black, 0.4);
  }
}
</style>
